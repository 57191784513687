.Sermons {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
    text-align: left;
    font-size: 1.2rem;
}

.status_container {
  margin-top: 5rem;
}

.retry_button {
  margin-left: 1.5rem;
  padding: 0.25rem 0.5rem;
}

@media (max-width: 60rem) {
  .Sermons {
    padding: 0rem;
    width: 98%;
  }
}