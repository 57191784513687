.header {
    min-height: var(--navbar-height);
}

.Navbar {
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
}

.Navbar a {
    font-size: 1.5em;
    text-decoration: none;
    color: black;
}

.Navbar ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
}

.hamburger_button {
    background-color: rgba(0, 0, 0, 0);
    width: 3rem;
    height: 3rem;
    border: none;
}

.hamburger {
    color: black;
    width: 2rem;
    height: 2rem;
}

/* .hidden {
    display: none;
} */

.home_name {
    font-size: 1.5rem;
    padding-right: 10rem;
}

.home_text {
    padding-left: 1rem;
}

.nav_items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
    flex-grow: .5;
}