.QueryFilterPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 75%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.filter_option {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  height: 2.3em;
}