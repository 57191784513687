.App {
  text-align: center;
  --accent-color: #94A7AE;
  --background-color: #F4F2F3;
  --navbar-height: 15rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: calc(100vh - var(--navbar-height)); */
  min-height: 100vh;
  width: 100%;
  background-color: var(--background-color);
}

.bubble {
  display: inline-block;
  padding: 1rem;
  text-align: left;
}