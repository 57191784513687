@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.number {
  font-family: sans-serif;
}

html { 
  background: url(/static/media/Background.b574bc09.jpg) no-repeat center center fixed;
  background-size: cover;
}
.App {
  text-align: center;
  --accent-color: #94A7AE;
  --background-color: #F4F2F3;
  --navbar-height: 15rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: calc(100vh - var(--navbar-height)); */
  min-height: 100vh;
  width: 100%;
  background-color: var(--background-color);
}

.bubble {
  display: inline-block;
  padding: 1rem;
  text-align: left;
}
.header {
    min-height: var(--navbar-height);
}

.Navbar {
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
}

.Navbar a {
    font-size: 1.5em;
    text-decoration: none;
    color: black;
}

.Navbar ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
}

.hamburger_button {
    background-color: rgba(0, 0, 0, 0);
    width: 3rem;
    height: 3rem;
    border: none;
}

.hamburger {
    color: black;
    width: 2rem;
    height: 2rem;
}

/* .hidden {
    display: none;
} */

.home_name {
    font-size: 1.5rem;
    padding-right: 10rem;
}

.home_text {
    padding-left: 1rem;
}

.nav_items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
    flex-grow: .5;
}
.Tab {
    flex-grow: 1;
    height: 5rem;
    padding: 0rem 1rem;
}

.Tab a {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Tab .active {
    font-weight: 600;
    color: #51c7ad;
}


.Sermons {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
    text-align: left;
    font-size: 1.2rem;
}

.status_container {
  margin-top: 5rem;
}

.retry_button {
  margin-left: 1.5rem;
  padding: 0.25rem 0.5rem;
}

@media (max-width: 60rem) {
  .Sermons {
    padding: 0rem;
    width: 98%;
  }
}
.PageNumbers {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page_button {
  margin: 0.25rem;
  height: 2rem;
  width: 3rem;
  font-size: 1.25rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.4rem;
  border-color: lightgrey;
}

.active_page_button {
  background-color: var(--accent-color);
}
.FilterDropdown select {
  height: 2em;
}
.QueryFilterPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 75%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.filter_option {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  height: 2.3em;
}
.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid lightgray;
}

.audio_container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 5rem;
  width: 20rem;
}

.id_data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 20rem;
  margin-left: 5rem;
}

.speaker {
  margin-left: 5rem;
}

@media (max-width: 60rem) {
  .Row {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .audio_container {
    margin-left: 0rem;
    width: 85vw;
  }

  .audio_container audio {
    width: 85vw;
  }

  .id_data {
    margin-left: 0rem;
    align-items: center;
  }

  .speaker {
    margin-left: 0rem;
  }
}

.VaccineStatement {
    font-size: 1.1em;
}

.VaccineStatement p {
    max-width: 50rem;
}

.bold_vaccine {
    font-weight: bold;
}

