.VaccineStatement {
    font-size: 1.1em;
}

.VaccineStatement p {
    max-width: 50rem;
}

.bold_vaccine {
    font-weight: bold;
}