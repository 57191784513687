.Tab {
    flex-grow: 1;
    height: 5rem;
    padding: 0rem 1rem;
}

.Tab a {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Tab .active {
    font-weight: 600;
    color: #51c7ad;
}