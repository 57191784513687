.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid lightgray;
}

.audio_container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 5rem;
  width: 20rem;
}

.id_data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 20rem;
  margin-left: 5rem;
}

.speaker {
  margin-left: 5rem;
}

@media (max-width: 60rem) {
  .Row {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .audio_container {
    margin-left: 0rem;
    width: 85vw;
  }

  .audio_container audio {
    width: 85vw;
  }

  .id_data {
    margin-left: 0rem;
    align-items: center;
  }

  .speaker {
    margin-left: 0rem;
  }
}